.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.layout_content {
  background-color: white;
  font-family: Roboto, sans-serif !important;
  position: absolute;

  height: 650px;
  overflow-y: auto;
  width: calc(100% - 70px);
  margin-left: 90px;
}

.pricingChart {
  transition: transform 0.3s ease;
}
.pricingChart:hover {
  opacity: 1;
  transform: scale(1.03);
}

.onDemand,
.reservation,
.spot {
  transition: transform 0.3s ease;
  transform: scale(1.03);
}

.test {
  cursor: pointer;
}
.healthButton .p-selectbutton .p-button {
  padding: 2px 6px;
  font-size: medium;
  margin: 2px;
}

.healthButton .p-selectbutton .p-button.p-highlight {
  background-color: #297cc5;
}
.forecast .p-selectbutton .p-button.p-highlight {
  background: #297cc5 !important;
}

.p-selectbutton .p-button {
  padding: 4px;
  font-weight: 500;
  font-size: small;
}
.forecast .p-selectbutton {
  font-family: "Neue Montreal", sans-serif !important;
  font-weight: bold;
  border: 1px solid #297cc5;
  border-radius: 5px;
}

.healthButton .p-selectbutton .p-button {
  border: 1px solid #ced4da;
  border-radius: 6px;
}
.icon_btn_primary {
  background: #297cc5 !important;
  border: none !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  font-family: "Neue Montreal", sans-serif !important;
  width: 35px !important;
  height: 35px !important;
}


.slide-out {
  transform: translateX(-10%);
  opacity: 0;
  Transition: all ease-in 1s;
}

.slide-in {
  transform: translateX(0) !important;
  opacity: 1 !important;
  Transition: all ease-in 0.5s;
}
