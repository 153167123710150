/* @import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,500;1,300&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,500;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@200;300&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Jost", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

.card {
  border: 3px solid rgb(12, 0, 58);
  padding: 50px;
  display: flex;
  justify-content: center;
  z-index: 100;
}

.accordian .p-accordion-header-text {
  font-size: 16px !important;
  color: #38546a !important;
  font-weight: 500 !important;
  margin: 0px 15px !important;
  line-height: 30px !important;
}

.accordian .p-accordion-toggle-icon {
  width: 25px !important;
  height: 25px !important;
  background-color: #14304a !important;
  color: white !important;
  border-radius: 4px !important;
}
.accordian .p-accordion-content {
  color: #587790 !important;
  padding: 10px 0px !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(8% + 1px) !important;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #ffffff !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #0f83c4 !important;
  stroke-linecap: square !important;
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 30px !important;
}

.loader-line {
  width: 200px;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 50px 35px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #297CC5;
  -webkit-animation: lineAnim 1.8s linear infinite;
  -moz-animation: lineAnim 1.8s linear infinite;
  animation: lineAnim 1.8s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.mobile-loader-line {
  width: 9vw;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 50px 35px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.mobile-loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #297CC5;
  -webkit-animation: lineAnim 1.8s linear infinite;
  -moz-animation: lineAnim 1.8s linear infinite;
  animation: lineAnim 1.8s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
      left: -40%;
  }
  50% {
      left: 20%;
      width: 80%;
  }
  100% {
      left: 100%;
      width: 100%;
  }
}

.p-field > .p-inputtext {
  height: 40px !important;
  background-color: #e6f4f1 !important;
  padding-left: 10px;
}

.p-float-label > .p-inputtext {
  height: 40px;
  background-color: #e6f4f1 !important;
  padding-left: 10px;
  margin-bottom: 5px;
}

.p-float-label > .textarea__content,
.textarea__content {
  min-height: 60px;
  background-color: #e6f4f1 !important;
  padding-left: 10px;
}

.custom-input {
  width: 465px;
}
.custom-input:focus {
  box-shadow: none !important;
  outline: none;
}
.custom-row:focus {
  box-shadow: none !important;
  outline: none;
}
.custom-row .p-inputtext {
  margin-top: 20px !important;
}

.custom-row {
  width: 220px;
}

.p-dropdown-panel {
  background-color: #e6f4f1 !important;
  border: 1px solid #e6f4f1 !important;
}
.customdropdown .p-dropdown-trigger {
  display: none !important;
}
.customdropdown .p-dropdown-label {
  display: flex !important;
  align-items: flex-end !important;
}

.btn-grad {
  /* background-image: linear-gradient(to bottom right, #e52d27 0%, #CF545C  51%, #e52d27  100%); */
  background-image: radial-gradient(
    circle at center,
    #e52d27 0%,
    #cf545c 51%,
    #e52d27 100%
  );

  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-grad-blue {
  /* background-image: linear-gradient(to bottom right, #e52d27 0%, #CF545C  51%, #e52d27  100%); */
  background-image: radial-gradient(
    circle at center,
    #0883c4 0%,
    #0562be 51%,
    #0883c4 100%
  );

  margin: 10px;
  padding: 5px 25px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 5px;
}
.btn-grad-red {
  /* background-image: linear-gradient(to bottom right, #e52d27 0%, #CF545C  51%, #e52d27  100%); */
  background-image: radial-gradient(
    circle at center,
    #e52d27 0%,
    #cf545c 51%,
    #e52d27 100%
  );

  margin: 10px;
  padding: 5px 25px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 5px;
}
.btn-grad-blue:hover,
.btn-grad-red:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-grad-submit {
  /* background-image: linear-gradient(to bottom right, #e52d27 0%, #CF545C  51%, #e52d27  100%); */
  background-image: radial-gradient(
    circle at center,
    #e52d27 0%,
    #cf545c 51%,
    #e52d27 100%
  );

  margin: 10px;
  padding: 5px 25px;

  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
}

.btn-grad-submit:hover,
.btn-grad-blog:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-grad-blog-disable {
  background-color: gray;

  /* margin: 10px; */
  padding: 5px 25px;

  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
}

.btn-grad-blog {
  /* background-image: linear-gradient(to bottom right, #e52d27 0%, #CF545C  51%, #e52d27  100%); */
  background-image: radial-gradient(
    circle at center,
    #e52d27 0%,
    #cf545c 51%,
    #e52d27 100%
  );

  /* margin: 10px; */
  padding: 5px 25px;

  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
}

@keyframes moveImageY {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveImageX {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

.pricing__img__shade {
  position: absolute;
  width: 550px;
  height: 250px;
  animation: moveImageX 2s ease infinite; /* Adjust the duration and easing as needed */
}

.pricing__img {
  /* position: relative; */
  /* width: 50rem; */
  /* height: 40rem; */
  width: 500px;
  height: auto;
  animation: moveImageY 3s ease infinite; /* Adjust the duration and easing as needed */
}

.pricing__img__init {
  width: 400px;
  height: auto;
  margin-left: 0px;
  animation: moveImageY 2s ease infinite;
}
.pricing__img__one {
  position: absolute;
  /* left: 280px; */
  margin-top: 25px;
  margin-left: 75px;
  width: 400px;
  height: auto;
  animation: moveImageY 3s ease infinite; /* Adjust the duration and easing as needed */
}

.device__bg {
  margin-right: 50px;
  position: absolute;
  width: 950px;
  height: auto;
  animation: moveImageX 2s ease infinite; /* Adjust the duration and easing as needed */
}
.desktop {
  position: absolute;

  margin-top: 30px;
  margin-right: 400px;
  margin-left: 0px;
  width: 600px;
  height: auto;
  animation: moveImageY 3s ease infinite;
}

.laptop {
  position: absolute;
  margin-left: 400px;
  margin-top: 200px;
  width: 600px;
  height: auto;
  animation: moveImageY 2s ease infinite;
}

.mobile {
  position: absolute;
  margin-top: 25px;
  margin-left: 600px;
  width: 150px;
  height: 300px;
  animation: moveImageY 3s ease infinite;
}

.p-progressbar {
  background-color: white !important;
  border-radius: 10px !important;
}

.customprogress .p-progressbar-value {
  background-color: #0883c4 !important;
  color: transparent !important;
}

.solution__border > span {
  border-radius: 3px;
}

.dot {
  padding: 5px;
  color: #0883c4;
  font-weight: 500;
}

.overview__grid__box {
  position: relative;

  transform: translate(0%, 75%);
}

.overview__grid__box:hover {
  transition: 0.5s;

  transform: translate(0%, 65%); /* Adjust the hover effect as needed */
}

.feature__image__grp {
  position: relative;

  transform: translate(0%, -75%);
}

.image-container {
  display: flex;
  overflow: hidden;
}

.image {
  margin-right: 10px; /* Adjust the margin between images */
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.visible {
  opacity: 1;
}

.hidden {
  display: none;
}

.terms__conditions__banner,
.privacy__policy__banner {
  height: 100px;
  background: rgb(2, 0, 36);
  background-image: linear-gradient(180deg, #003366 39%, #1667b8 100%);
}

.content__paragraph > p {
  margin-bottom: 20px;
}

.location__icon {
  background-color: #003366;
  color: white;
  padding: 12px;
  border-radius: 10px;
  font-size: 25px;
}

.service__box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.service__box:hover {
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(8, 131, 196, 0.16) 0px 10px 36px 0px,
    rgba(8, 131, 196, 0.06) 0px 0px 0px 1px;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

@keyframes moveX {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translate(50px, 0);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveY {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translate(0px, -50px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveZ {
  0% {
    transform: translateZ(0);
  }
  50% {
    transform: translate(25px, -25px);
  }
  100% {
    transform: translateZ(0);
  }
}

.cloudX {
  position: relative;
  width: 650px;
  height: auto;
  z-index: 1;
  margin-left: 100px;
  margin-right: -100px;
  margin-top: 10px;
  margin-bottom: -10px;

  animation: moveY 3s ease infinite;
}

.cloudY {
  position: relative;
  width: 650px;
  height: auto;
  z-index: 1;
  margin-left: -140px;
  margin-right: 140px;
  margin-top: -45px;
  margin-bottom: 45px;
  animation: moveX 3s ease infinite;
}

.cloudZ {
  position: relative;
  width: 650px;
  height: auto;
  margin-left: -140px;
  margin-right: 140px;
  margin-top: 45px;
  margin-bottom: -45px;
  /* transition: opacity 0.5s ease-in-out; */
  animation: moveZ 3s ease infinite;
}

.cloudCenter {
  position: relative;
  width: 650px;
  /* margin-top: -19px; */
  /* margin-bottom: 19px; */
  height: auto;
  margin-left: 100px;
  margin-right: -100px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.text__gradient {
  font-size: 30px;
  background: -webkit-linear-gradient(0deg, #e52d27 0%, #0883c4 100%);
  /* background: -webkit-linear-gradient(0deg, #0883c4 0%, #e52d27 100%); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact__content {
  padding-bottom: 100px;
}

.contact__content__bal {
  padding-top: 75px;
}

.contact__banner {
  position: relative;
  z-index: 10;
  margin-top: -108px;
  margin-bottom: -108px;
}

#scrollToTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  font-size: 16px;
  background-color: #0883c4;
  color: white;
  border: none;
  cursor: pointer;
  padding: 12px;
  transition: opacity 0.4s;
}

#scrollToTopBtn:hover {
  cursor: pointer;
  background-color: #e52d27;
}

#scrollToTopBtn.show {
  display: block;
}

#scrollToTopBtn.hide {
  display: none;
}
.p-progress-spinner-circle {
  stroke: #e6f4f1 !important; /* Change the color to blue */
}

.btn_primary {
  background: #297cc5 !important;
  border-radius: 8px !important;
  border: none !important;
  font-family: "Neue Montreal", sans-serif !important;
  letter-spacing: 0.3px !important;
  line-height: 0px !important;
}

.btn_csv_primary {
  background: #297cc5 !important;
  height: 35px !important;
}

.p-column-header-content .p-inputtext {
  width: 70px;
}
.p-icon {
  pointer-events: none;
}
.p-sortable-column-icon {
  color: #ca0700c9;
  width: 12px;
}
.btn_grey {
  background: linear-gradient(
    90deg,
    rgb(168, 168, 168) 0%,
    rgb(150, 150, 150) 34%,
    rgb(112, 112, 112) 100%
  ) !important;
  border-radius: 8px !important;
  border: none !important;
}
.p-button-icon {
  width: 30px;
}

.p-button .p-button-icon-left {
  margin-right: 0px !important;
}
.rolesCard .p-card-header h1 {
  font-family: "Roboto", sans-serif !important;
}

.custom_select {
  border: 1px solid #e5e7eb;
}

.p-dropdown-empty-message {
  display: none;
}

.custom_select {
  border: 1px solid #e5e7eb;
}

.p-dropdown-empty-message {
  display: none;
}
.p-tooltip-text {
  padding: 7.5px !important;
  font-size: 12.5px !important;
  box-shadow: none !important;
}
.p-card .p-card-footer {
  padding: 0px;
}
