@media screen and (max-width: 767px) {
    .web__view__header {
        display: none; /* Hide the component on screens up to 768px wide */
    }

    .mobile-btn-grad {
        /* background-image: linear-gradient(to bottom right, #e52d27 0%, #CF545C  51%, #e52d27  100%); */
        background-image: radial-gradient(circle at center, #e52d27 0%, #cf545c 51%, #e52d27 100%);

        margin: 10px;
        padding: 12px 25px;
        text-align: center;
        /* text-transform: uppercase; */
        transition: 0.5s;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        display: block;
    }
}

@media only screen and (min-width: 1025px) {
    .mobile__view__header {
        display: none; /* Hide the component on screens up to 768px wide */
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .mobile__view__header {
        display: none; /* Hide the component on screens up to 768px wide */
    }
}
