.p-datatable-thead th {
    background-color: #e2f1ff !important;
    /* color: #297cc5 !important; */
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: normal !important;
    padding: 0.2rem 1rem !important;
    font-family: roboto,sans-serif !important;
    height: 41px !important;
  }
  .p-inputtext::placeholder {
    font-size: 12px; /* Set placeholder text size */
  }
  .p-datatable-thead .secondary-header {
    background-color: #f4faff !important;
    color: #000000 !important;
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: normal !important;
    padding: 0.8rem 1rem !important;
    font-family: roboto,sans-serif !important;
  }
  .p-datatable-thead .frozenTableHeader {
    background-color: #cee3f7 !important;
    color: #297cc5 !important;
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: normal !important;
    padding: 0.2rem 1rem !important;
    font-family: roboto,sans-serif !important;
  }
  .p-datatable > .p-datatable-wrapper {
    border-radius: 10px 10px 0px 0px;
    border: none;
  }
  .p-datatable .p-paginator-bottom {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
    padding-bottom: 15px;
    padding-top: 16px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -1px rgb(0 0 0 / 0.06);
    border-radius: 6px;
  }
  
  .p-datatable {
    border-radius: 0.5rem;
  }
  .p-datatable-tbody tr td {
    padding: 0.3rem 1rem !important;
    font-size: 14px;
    font-weight: normal;
    color: #333333;
  }
  .p-datatable-scrollable-table > .p-datatable-thead {
    font-family: roboto,sans-serif;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.03em;
  }
  .p-datatable-header {
    border: none !important;
    background: transparent !important;
    margin-bottom: 5px;
    font-weight: normal;
  }
  .p-datatable-tbody {
    font-family: roboto,sans-serif;
    min-height: 450px;
  }
  .header_field {
    border: 1px solid #e3e3e3 !important;
  }
  .header_field:focus {
    -tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow) !important;
  }
  
  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
    display: flex !important;
    align-items: center !important;
  }
  
  .p-paginator ::-webkit-scrollbar {
    display: none;
  }
  .p-paginator ::-webkit-scrollbar-thumb {
    display: none;
  }
  .p-datatable::-webkit-scrollbar-thumb {
    display: none !important;
    scrollbar-width: none !important;
    width: 0 !important;
  }
  
  .cube-table .p-component-overlay {
    background-color: #dee2e6 !important;
    opacity: 0.5;
  }
  
  .p-datatable > .p-datatable-wrapper {
    overflow: auto;
    border-radius: 10px;
  }
  
  .p-treetable-scrollable-view:first-child
    > .p-treetable-scrollable-header
    > .p-treetable-scrollable-header-box
    > .p-treetable-scrollable-header-table
    > .p-treetable-thead
    tr
    th:first-child {
    background-color: #cee3f7 !important;
    border-top-left-radius: 0.5rem !important;
  
    color: #297cc5 !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .p-treetable-scrollable-view:first-child
    > .p-treetable-scrollable-header
    > .p-treetable-scrollable-header-box
    > .p-treetable-scrollable-header-table
    > .p-treetable-thead
    tr:nth-child(2)
    th {
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
    color: #495057 !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }
  
  .p-treetable-scrollable-view:last-child
    > .p-treetable-scrollable-header
    > .p-treetable-scrollable-header-box
    > .p-treetable-scrollable-header-table
    > .p-treetable-thead
    tr
    th {
    background-color: #e2f1ff !important;
    color: #297cc5 !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .p-treetable-scrollable-view:last-child
    > .p-treetable-scrollable-header
    > .p-treetable-scrollable-header-box
    > .p-treetable-scrollable-header-table
    > .p-treetable-thead
    tr:nth-child(2)
    th {
    background-color: #f0f9ff !important;
    color: #333333 !important;
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .p-treetable-scrollable-view:last-child > .p-treetable-scrollable-header {
    border-top-right-radius: 0.5rem !important;
    border-top-left-radius: 0rem !important;
  }
  .p-treetable-scrollable-view:last-child
    > .p-treetable-scrollable-header
    > .p-treetable-scrollable-header-box
    > .p-treetable-scrollable-header-table
    > .p-treetable-thead
    th:last-child {
    border-top-right-radius: 0.5rem !important;
    border-top-left-radius: 0rem !important;
  }
  .p-datatable-wrapper::-webkit-scrollbar {
    width: 14px;
    background-color: #f1f1f1;
  }
  
  .p-datatable-wrapper::-webkit-scrollbar-thumb {
    background-color: #c3c3c3;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }
  
  .p-datatable-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #a855f7;
  }
  @media (max-width: 1024px) {
    .p-paginator .p-paginator-current {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .p-paginator .p-paginator-first {
      display: none;
    }
    .p-paginator .p-paginator-last {
      display: none;
    }
  }
  @media (max-width: 425px) {
    .p-paginator .p-dropdown {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .cube-table .p-datatable-tbody tr td {
      padding: 0.5rem 0.5rem !important;
      font-size: 12px !important;
    }
  }
  @media screen and (max-width: 768px) {
    .cube-table .p-datatable-thead th {
      padding: 0.8rem 2rem !important;
      font-size: 12px !important;
    }
  }
  @media screen and (max-width: 320px) {
    .cube-table .p-datatable-tbody tr td {
      padding: 0.3rem 2rem !important;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 320px) {
    .cube-table .p-datatable-thead th {
      padding: 0.8rem 2rem !important;
      font-size: 14px !important;
    }
  }
  .p-treetable > .p-treetable-wrapper {
    overflow: hidden !important;
  }
  .p-dropdown .p-dropdown-label {
    display: flex !important;
    align-items: center !important;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    border: 1px solid #dee2e6 !important;
    border-width: 0 0 2px 0 !important;
  }

  .p-datatable .p-inputtext {
    font-family: Neue Montreal, sans-serif !important;
    font-size: 15px !important;
    letter-spacing: .9px !important;
    padding: 4px 10px !important;
    border: 1px solid #ced4da;
    transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    color: #495057;
    border-radius: 6px;
  }
