.customTree .p-component-overlay {
  background-color: white !important;
  opacity: 0.5;
}

@media (min-width: 768px) {
  .sticky_header {
    position: sticky;
    left: 0;
  }
}
@media (min-width: 768px) {
  .sticky_cell {
    /* position: sticky; */
    left: 0;
    background-color: white;
  }
}
/* .p-treetable .p-treetable-scrollable-body::-webkit-scrollbar {
  width: 14px;
}

.p-treetable .p-treetable-scrollable-body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.p-treetable .p-treetable-scrollable-body::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.p-treetable .p-treetable-scrollable-body::-webkit-scrollbar-thumb:hover {
  background-color: lightgray;
} */

.groupView .p-treetable-scrollable-body::-webkit-scrollbar-track {
  margin-left: 450px;
  background-color: #f1f1f1;
}
.p-treetable .p-treetable-scrollable-body::-webkit-scrollbar {
  width: 14px;
}

.p-treetable .p-treetable-scrollable-body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.p-treetable .p-treetable-scrollable-body::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.cube-tree-view {
  max-width: 767px;
  overflow-x: scroll;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cube-tree-view {
    max-width: 1024px;
    overflow-x: scroll;
  }
}

@media (min-width: 1025px) {
  .cube-tree-view {
    max-width: none;
    overflow-x: auto;
  }
}
.p-checkbox-box.p-highlight {
  border-color: #297cc5 !important;
  background: #297cc5 !important;
}

.p-checkbox-box:hover {
  border-color: #297cc5 !important;
}
.p-row-odd {
  border: 1px solid #dee2e6 !important;
  border-width: 0 0 2px 0 !important;
}
.p-treetable-thead tr:nth-child(1) .p-column-header-content {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4rem;
}

.p-treetable-thead
  tr:nth-child(2)
  .sticky_header
  .p-column-header-content
  .p-column-title {
  padding-left: 4rem;
}
.sticky_header {
  padding: 0px !important;
}
/* .p-treetable-tbody .custom-section-checkbox{
display: flex;
align-items: center;
} */

.p-column-header-content .p-inputtext {
  width: 10rem;
  padding-left: 2px;
}

tr:nth-child(1) .p-column-header-content > .p-column-title svg {
  display: none;
}
.p-checkbox .p-checkbox-box {
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 6px;
  color: #495057;
  height: 1.1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  width: 1.1rem;
}

.p-paginator-bottom {
  pointer-events: none;
}

.p-column-title {
  white-space: nowrap;
}

.p-treetable .p-treetable-tbody > tr > td {
  padding: 0 1rem !important;
}
.p-treetable .p-inputtext {
  font-family: Neue Montreal, sans-serif !important;
  font-size: 15px !important;
  letter-spacing: .9px !important;
  padding: 2px 10px !important;
  border: 1px solid #ced4da;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
  color: #495057;
  border-radius: 6px;
}
