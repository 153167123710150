@keyframes moveX {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translate(50px, 0);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes moveY {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translate(0px, -50px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes moveZ {
    0% {
        transform: translateZ(0);
    }
    50% {
        transform: translate(25px, -25px);
    }
    100% {
        transform: translateZ(0);
    }
}

@media screen and (max-width: 767px) {
    .web__view__service {
        display: none; /* Hide the component on screens up to 768px wide */
    }

    .cloudX {
        position: relative;
        width: 325px;
        height: auto;
        z-index: 1;
        margin-left: 10px;
        margin-right: -10px;
        margin-top: 10px;
        margin-bottom: -10px;

        animation: moveY 3s ease infinite;
    }

    .cloudY {
        position: relative;
        width: 325px;
        height: auto;
        z-index: 1;
        margin-top: -5px;
        margin-bottom: 5px;
        animation: moveX 3s ease infinite;
    }

    .cloudZ {
        position: relative;
        width: 325px;
        height: auto;
        margin-top: 5px;
        margin-bottom: -5px;
        /* transition: opacity 0.5s ease-in-out; */
        animation: moveZ 3s ease infinite;
    }

    .cloudCenter {
        position: relative;
        width: 325px;
        /* margin-top: -19px; */
        /* margin-bottom: 19px; */
        height: auto;
        margin-left: 10px;
        margin-right: -10px;
        margin-top: -10px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 1025px) {
    .mobile__view__service {
        display: none; /* Hide the component on screens up to 768px wide */
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .web__view__service {
        display: none; /* Hide the component on screens up to 768px wide */
    }

    .cloudX {
        position: relative;
        width: 450px;
        height: auto;
        z-index: 1;
        margin-left: 10px;
        margin-right: -10px;
        margin-top: 0px;
        margin-bottom: -10px;
        padding-bottom: 10px;
        padding-right: 5px;

        animation: moveY 3s ease infinite;
    }

    .cloudY {
        position: relative;
        width: 450px;
        height: auto;
        z-index: 1;
        margin-top: -45px;
        margin-bottom: 5px;
        margin-left: -155px;
        animation: moveX 3s ease infinite;
    }

    .cloudZ {
        position: relative;
        width: 450px;
        height: auto;
        margin-top: 5px;
        margin-bottom: -15px;
        padding-left: 15px;
        /* transition: opacity 0.5s ease-in-out; */
        animation: moveZ 3s ease infinite;
    }

    .cloudCenter {
        position: relative;
        width: 450px;
        /* margin-top: -19px; */
        /* margin-bottom: 19px; */
        height: auto;
        margin-left: 10px;
        margin-right: -10px;
        margin-top: -10px;
        margin-bottom: 10px;
    }
}
