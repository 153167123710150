@media screen and (max-width: 768px) {
    .web__view__solution {
        display: none; /* Hide the component on screens up to 768px wide */
    }
}

@media only screen and (min-width: 1025px) {
    .mobile__view__solution {
        display: none; /* Hide the component on screens up to 768px wide */
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .web__view__solution {
        display: none; /* Hide the component on screens up to 768px wide */
    }
}
