@media screen and (max-width: 767px) {
    .mobile__footer__head {
        display: flex;
        flex-direction: column;
    }

    .mobile__footer__logo {
        display: none;
    }

    .mobile__footer__copyright {
        display: flex;
        justify-content: center;
    }
}
