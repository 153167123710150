@keyframes moveImageY {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes moveImageX {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-20px);
    }
    100% {
        transform: translateX(0);
    }
}

@media screen and (max-width: 767px) {
    .web__view__soluiton {
        display: none;
    }
    .mobile__view__feature__header {
        margin: 25px;
    }

    .mobile__view__feature__content {
        display: flex;
        margin: 25px;
        flex-direction: column;
    }
    .mobile__view__feature__content > div {
        width: 100%;
        margin: 25px 0px;
    }

    .pricing__img__shade {
        position: absolute;
        width: 300px;
        height: 220px;
        animation: moveImageX 2s ease infinite; /* Adjust the duration and easing as needed */
    }

    .pricing__img {
        width: 400px;
        height: auto;
        animation: moveImageY 3s ease infinite; /* Adjust the duration and easing as needed */
    }

    .pricing__img__init {
        width: 300px;
        height: auto;
        margin-left: -20px;
        margin-bottom: 35px;

        animation: moveImageY 2s ease infinite;
    }
    .pricing__img__one {
        position: absolute;
        margin-top: 35px;
        margin-left: 25px;
        width: 300px;
        height: auto;
        animation: moveImageY 3s ease infinite; /* Adjust the duration and easing as needed */
    }

    .feature__image__grp {
        position: relative;

        transform: translate(0%, -30%);
    }

    .device__bg {
        margin-right: 50px;
        position: absolute;
        width: 475px;
        height: auto;
        animation: moveImageX 2s ease infinite; /* Adjust the duration and easing as needed */
    }
    .desktop {
        position: absolute;

        margin-top: 15px;
        margin-right: 100px;
        margin-left: 0px;
        width: 250px;
        height: auto;
        animation: moveImageY 3s ease infinite;
    }

    .laptop {
        position: absolute;
        margin-left: 75px;
        margin-top: 100px;
        width: 250px;
        height: auto;
        animation: moveImageY 2s ease infinite;
    }

    .mobile {
        position: absolute;
        margin-top: 25px;
        margin-left: 250px;
        width: 75px;
        height: 150px;
        animation: moveImageY 3s ease infinite;
    }
}

@media only screen and (min-width: 1025px) {
    .mobile__view__solution {
        display: none; /* Hide the component on screens up to 768px wide */
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .web__view__solution {
        display: none; /* Hide the component on screens up to 768px wide */
    }

    tbody {
        width: 500px;
    }

    .pricing__img__shade {
        position: absolute;
        width: 350px;
        height: 180px;
        margin-top: 95px;
        animation: moveImageX 2s ease infinite; /* Adjust the duration and easing as needed */
    }

    .pricing__img {
        width: 350px;
        height: auto;
        margin-top: 85px;
        animation: moveImageY 3s ease infinite; /* Adjust the duration and easing as needed */
    }

    .pricing__img__init {
        width: 350px;
        height: auto;
        margin-left: -25px;
        margin-top: 100px;
        margin-bottom: 35px;

        animation: moveImageY 2s ease infinite;
    }
    .pricing__img__one {
        position: absolute;
        margin-top: 165px;
        margin-left: 50px;
        width: 300px;
        height: auto;
        animation: moveImageY 3s ease infinite; /* Adjust the duration and easing as needed */
    }

    .device__bg {
        margin-top: -75px;
        margin-left: 30px;
        margin-right: 0px;
        position: absolute;
        width: 700px;
        height: auto;
        animation: moveImageX 2s ease infinite; /* Adjust the duration and easing as needed */
    }

    .desktop {
        position: absolute;

        margin-top: -105px;
        margin-right: 250px;
        margin-left: 0px;
        width: 500px;
        height: auto;
        animation: moveImageY 3s ease infinite;
    }

    .laptop {
        position: absolute;
        margin-left: 250px;
        margin-top: 50px;
        width: 500px;
        height: auto;
        animation: moveImageY 2s ease infinite;
    }

    .mobile {
        position: absolute;
        margin-top: -75px;
        margin-left: 450px;
        width: 125px;
        height: 250px;
        animation: moveImageY 3s ease infinite;
    }
}
