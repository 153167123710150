@media screen and (max-width: 767px) {
    .web__view__overview {
        display: none; /* Hide the component on screens up to 768px wide */
    }

    .mobile__overview__grid__box {
        position: relative;
    }

    .mobile__overview__grid__box:hover {
        transition: 0.5s;
    }

    .mobile-why-choose {
        flex-direction: column;
    }

    .mobile-trial-sign-up {
        flex-direction: column;
    }
}

@media only screen and (min-width: 1025px) {
    .mobile__view__overview {
        display: none; /* Hide the component on screens up to 768px wide */
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .web__view__overview {
        display: none; /* Hide the component on screens up to 768px wide */
    }

    .mobile-why-choose {
        flex-direction: row;
    }

    .mobile-trial-sign-up {
        flex-direction: row;
    }

    .btn-grad-blue {
        margin-left: 0px;
    }
}
