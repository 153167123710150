@media screen and (max-width: 767px) {
    .mobile__view__contactdetails {
        display: flex;
        flex-direction: column;
    }

    .custom-input {
        width: 100%;
    }

    .custom-row {
        width: 100%;
    }

    .mobile__view__contactform {
        margin: 25px;
    }

    .mobile__view__contactcontent {
        margin: 25px;
    }

    .text__gradient {
        font-size: 20px;
        background: -webkit-linear-gradient(0deg, #e52d27 0%, #0883c4 100%);
        /* background: -webkit-linear-gradient(0deg, #0883c4 0%, #e52d27 100%); */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    /* .contact__banner__partner{
    display: none;
  } */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .mobile__view__contactform {
        margin-left: 0px;
        margin-right: -45px;
    }

    .mobile__view__contactcontent {
        padding-right: 0px;
    }
}
