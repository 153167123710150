.modal-preview-container-byo {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background:url('../../.././assets/icons/byoBlurImage.jpg') center/contain  no-repeat;
    background-position: top;
    backdrop-filter: blur(1px); 
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .modal-preview-container-paas {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background:url('../../.././assets/icons/paasBlurImage.jpg') center/contain  no-repeat;
    background-position: top;
    backdrop-filter: blur(1px); 
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .modal-preview-container-roi {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background:url('../../.././assets/icons/roiBlurImage.jpg') center/contain  no-repeat;
    background-position: top;
    backdrop-filter: blur(1px); 
    display: flex;
    justify-content: center;
    align-items: start;
  }
  
  .modal-preview-content {
    background-color: #00a1f1;

    color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .modal-preview-content p {
    font-size: 18px;
    font-weight: bold;
  }
  